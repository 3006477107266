import React, { Suspense } from "react";

import { Routes, Route } from "react-router-dom";

import { Spinner } from "react-bootstrap";

import ProtectedRoutes from "./ProtectedRoutes";

import NonAuthRoutes from "./NonAuthRoutes";

//add all routes component using lazy
const LoginPage = React.lazy(() => import("../Pages/Login"));
const DriverDashboard = React.lazy(() => import("../Pages/Dashboard/Driver"));
const Page404 = React.lazy(() => import("../Pages/ErrorPage/Page404/Page404"));
const AddDriver = React.lazy(() =>
  import("../Pages/Dashboard/Driver/AddDriver")
);
const DriverDetails = React.lazy(() =>
  import("../Pages/Dashboard/Driver/DriverDetails")
);
const Drivertrack = React.lazy(() =>
  import("../Pages/Dashboard/Driver/TrackDriver")
);

const RouteModule = () => {
  return (
    <React.Fragment>
      {/* add suspense fallback to handle lazy loading */}
      <Suspense
        fallback={
          <div className="lazy_spinner">
            <Spinner animation="border" />
          </div>
        }
      >
        <Routes>
          {/* public routes */}
          <Route
            path="/"
            element={
              <NonAuthRoutes>
                <LoginPage />
              </NonAuthRoutes>
            }
          />

          {/* private routes */}
          <Route
            path="/dashboard/driver"
            element={
              <ProtectedRoutes>
                <DriverDashboard />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard/driver/add"
            element={
              <ProtectedRoutes>
                <AddDriver />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard/driver/report"
            element={
              <ProtectedRoutes>
                <DriverDetails />
              </ProtectedRoutes>
            }
          />
          <Route
            path="/dashboard/driver/edit"
            element={
              <ProtectedRoutes>
                <AddDriver />
              </ProtectedRoutes>
            }
          />

          <Route
            path="/dashboard/driver/track"
            element={
              <ProtectedRoutes>
                <Drivertrack />
              </ProtectedRoutes>
            }
          />

          {/* 404 page functionality */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
};
export default RouteModule;
