import React from "react";
// import routes
import { useLocation,Navigate } from "react-router-dom";

const ProtectedRoutes = ({ children }) => {
  const authToken = localStorage.getItem("auth");
  let location = useLocation();
  
  // check authToken for private routes
  if (!authToken ) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoutes;
